// src/components/ui/button.jsx
import React from 'react';

export const Button = ({ children, variant = 'primary', ...props }) => {
  const getVariantClasses = () => {
    switch (variant) {
      case 'secondary':
        return 'bg-white text-gray-900 hover:bg-gray-100';
      case 'outline':
        return 'bg-transparent border-2 border-white text-white hover:bg-white/10';
      default:
        return 'bg-blue-600 text-white hover:bg-blue-700';
    }
  };

  return (
    <button
      className={`px-6 py-3 rounded-md font-medium transition-colors ${getVariantClasses()}`}
      {...props}
    >
      {children}
    </button>
  );
};