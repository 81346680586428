// Updated src/App.jsx with all sections
import React from 'react';
import { Button } from './components/ui/button';
import {
  Mail,
  Linkedin,
  Phone,
  AlertCircle,
  Users,
  Cog,
  Code,
  Clock,
  MessageSquare,
  DollarSign,
  HourglassIcon,
  UsersIcon,
  CheckCircle,
  Twitter,
  Facebook,
  Instagram,
  BarChart,
} from 'lucide-react';

function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <main className="flex-grow">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-gradient-to-r from-blue-600 to-purple-600">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none text-white">
                  QAI: Revolutionize Your Testing Productivity
                </h1>
                <p className="mx-auto max-w-[700px] text-white md:text-xl">
                  Streamline your QA process, reduce costs, and boost development efficiency with QAI's innovative testing solution.
                </p>
              </div>
              <div className="space-x-4">
                <Button 
                  variant="secondary" 
                  onClick={() => {
                    console.log('Button clicked');
                    window.open('https://forms.gle/B2GYzqFE6xBbBtEk9', '_blank');
                  }}
                  className="bg-white text-blue-600 hover:bg-blue-50 hover:scale-105 transform transition-all duration-200 font-semibold text-lg px-8 py-3 rounded-full shadow-lg hover:shadow-xl flex items-center gap-2"
                >
                  Join Waitlist
                  <HourglassIcon className="w-5 h-5 animate-pulse" />
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">
              The Challenges QAI Solves
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              {[
                { title: "Extended Testing Timelines", icon: Clock, description: "QA often takes a full month for testing, extending project timelines significantly." },
                { title: "Resource Imbalance", icon: Users, description: "Companies struggle with dev-to-QA ratios, often having 22 devs to just 5-8 QA engineers." },
                { title: "Inefficient Communication", icon: MessageSquare, description: "Constant back-and-forth between devs and QA wastes 3-4 days on UI changes alone." },
                { title: "Automation Challenges", icon: Cog, description: "QAs struggle to implement effective automation, leading to prolonged manual testing." },
                { title: "Context Switching Costs", icon: AlertCircle, description: "Developers lose productivity due to frequent context switching for QA-related tasks." },
                { title: "Scalability Issues", icon: BarChart, description: "As teams grow, traditional QA processes fail to scale, leading to bottlenecks." },
              ].map((problem, index) => (
                <div key={index} className="group relative overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-purple-500 opacity-75 transition-opacity group-hover:opacity-100" />
                  <div className="relative p-6 flex flex-col items-center text-center">
                    <problem.icon className="w-12 h-12 text-white mb-4" />
                    <h3 className="text-xl font-semibold text-white mb-2">{problem.title}</h3>
                    <p className="text-white">{problem.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">The Impact of Inefficient QA</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold mb-2">25% of Project Time</h3>
                <p>Spent on QA testing alone, often taking a full month in a 4-month development cycle.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold mb-2">6x Longer Testing</h3>
                <p>Features that take half a day to develop often require 3 days of QA testing.</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg">
                <h3 className="text-2xl font-semibold mb-2">20-30 Days Lost</h3>
                <p>QAs spend this time just understanding and designing test cases for new features.</p>
              </div>
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-12">QAI's Solution</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {[
                { title: "Automated Test Case Generation", icon: Cog, description: "QAI uses AI to automatically generate comprehensive test cases, reducing the 20-30 days typically spent on test case design to mere hours." },
                { title: "Intelligent Test Prioritization", icon: BarChart, description: "Our system analyzes code changes and prioritizes tests, ensuring critical paths are tested first, reducing overall testing time by up to 50%." },
                { title: "Seamless Dev-QA Collaboration", icon: UsersIcon, description: "QAI's platform facilitates real-time communication between developers and QA, eliminating the 3-4 day lag often seen in UI change iterations." },
                { title: "Scalable QA Processes", icon: CheckCircle, description: "Our solution scales with your team, maintaining testing efficiency even as you grow from 1 QA per 18 devs to larger, more complex team structures." },
              ].map((solution, index) => (
                <div key={index} className="group relative overflow-hidden rounded-lg shadow-lg transition-all duration-300 hover:shadow-xl hover:-translate-y-1">
                  <div className="absolute inset-0 bg-gradient-to-r from-green-500 to-teal-500 opacity-75 transition-opacity group-hover:opacity-100" />
                  <div className="relative p-6 flex flex-col items-center text-center">
                    <solution.icon className="w-12 h-12 text-white mb-4" />
                    <h3 className="text-xl font-semibold text-white mb-2">{solution.title}</h3>
                    <p className="text-white">{solution.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="w-full py-12 md:py-24 lg:py-32">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8">QAI's Impact</h2>
            <p className="text-xl text-center max-w-[800px] mx-auto mb-12">
              Our research shows that QAI can dramatically improve your testing productivity and overall development efficiency:
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <h3 className="text-3xl font-bold text-blue-600 mb-2">60%</h3>
                <p>Reduction in overall QA testing time</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <h3 className="text-3xl font-bold text-blue-600 mb-2">75%</h3>
                <p>Decrease in back-and-forth communication between devs and QA</p>
              </div>
              <div className="bg-white p-6 rounded-lg shadow-lg text-center">
                <h3 className="text-3xl font-bold text-blue-600 mb-2">90%</h3>
                <p>Faster test case generation and management</p>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="bg-gray-900 text-white">
        <div className="container mx-auto px-6 py-12">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="mb-8 md:mb-0">
              <h3 className="text-2xl font-bold mb-4">QAI</h3>
              <p className="text-gray-400">
                Revolutionizing the way teams approach software testing and quality assurance.
              </p>
            </div>
            <div>
              <h4 className="text-lg font-semibold mb-4">Contact Us</h4>
              <ul className="space-y-2">
                <li className="flex items-center">
                  <Mail className="h-5 w-5 mr-2" />
                  <a href="mailto:shyojimeena0@gmail.com" className="hover:text-blue-400 transition-colors">
                    shyojimeena0@gmail.com
                  </a>
                </li>
                <li className="flex items-center">
                  <Phone className="h-5 w-5 mr-2" />
                  <a href="tel:+917357756877" className="hover:text-blue-400 transition-colors">
                    +91-7357756877
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="border-t border-gray-800 mt-8 pt-8 flex flex-col sm:flex-row justify-between items-center">
            <p className="text-gray-400 text-sm">&copy; 2024 QAI. All rights reserved.</p>
            <div className="flex space-x-4 mt-4 sm:mt-0">
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <Twitter className="h-6 w-6" />
                <span className="sr-only">Twitter</span>
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <Facebook className="h-6 w-6" />
                <span className="sr-only">Facebook</span>
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <Instagram className="h-6 w-6" />
                <span className="sr-only">Instagram</span>
              </a>
              <a href="#" className="text-gray-400 hover:text-white transition-colors">
                <Linkedin className="h-6 w-6" />
                <span className="sr-only">LinkedIn</span>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;